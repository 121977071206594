body{
  background-color: #d7f5f9;
  width: 100%;
  color: white;
}
.App {
  width: 100%;
  text-align: center;
}
.App-logo {
  height: 250px;
  filter: drop-shadow(4px 4px 3px #bfdade) drop-shadow(-4px -4px 3px #efffff);
}
.ahref {
  height: 100px;
  width: 230px;
  border-radius: 12px;
  color: #2b336a;
  display: flex;
    justify-content: center;
    align-items: center;
  text-transform: none;
  text-decoration: none;
  font-size: 25px;
  border-radius: 20px;
  background: #d7f5f9;
  box-shadow:  6px 6px 12px #bfdade, 
               -6px -6px 12px #efffff;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
/* Scale up the box */
.ahref:hover {
  transform: scale(1.2, 1.2);
}
.ahref:active {
  text-decoration: none;
  text-transform: none;
  color: #2b336a;
}
.ahref:visited {
  text-decoration: none;
  text-transform: none;
  color: #2b336a;
}

/* Fade in the pseudo-element with the bigger shadow */
.ahref:hover::after {
  opacity: 1;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}